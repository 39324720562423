import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="paper"
export default class extends Controller {
  connect() {
  }

  delete(event) {
    let confirmed = window.confirm("Are you sure you want to delete this item?")
    if (!confirmed) {
      event.preventDefault();
    }
  }

  other_file(event) {
    let id = event.currentTarget.dataset.id.slice("other_file_button_".length)
    let other_file = document.getElementById("other_file_container_" + id)
    other_file.classList.toggle("hidden");
  }
}
