import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="board-members"
export default class extends Controller {

  static targets = [ "boardMembersSection", "parallaxFast", "parallaxSlower", "parallaxVerySlower" ]

  connect() {
    this.topOnRefresh()
    window.addEventListener('scroll', this.animateBackgroundOnScroll.bind(this));
    window.addEventListener('scroll', this.parallaxOnScroll.bind(this));
  }

  parallaxOnScroll() {
    if (window.matchMedia("(min-width: 768px)").matches) {

    const factor = 0.3;
    const scrollTop = window.pageYOffset;
    const offset = this.element.offsetTop;
    const position = (offset - scrollTop) * factor;

    this.parallaxFastTargets.map((e) => e.style.transform = `translateY(${position}px)`);
    this.parallaxSlowerTargets.map((e)=> e.style.transform = `translateY(${position * 0.4}px)`)
    this.parallaxVerySlowerTargets.map((e)=> e.style.transform = `translateY(${position * 0.1}px)`)

    }
  }

  animateBackgroundOnScroll() {
    const maxHeight = document.body.scrollHeight - window.innerHeight
    const currentScroll = window.scrollY

    // calculate the percentage of scroll position
    const scrollPercentage = (currentScroll / maxHeight) * 100;

    // calculate the red, green, and blue values based on the percentage of scroll position
    const red = Math.round((210 * scrollPercentage) / 100);
    const green = Math.round((210 * scrollPercentage) / 100);
    const blue = Math.round((250 * scrollPercentage) / 100);
    const opacity = parseFloat(Math.max(0, Math.min(1, scrollPercentage / 100)).toFixed(1)); ;

    // set the background color of the element
    this.boardMembersSectionTarget.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`;

    if (currentScroll > 5) {
      this.boardMembersSectionTarget.classList.remove('animate-bg-color');
    }
  }

  topOnRefresh() {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }
  }
}
