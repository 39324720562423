import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {

 static targets = ["notification"]


  connect() {
    if (!this.isPreview) {
      // Display with transition
      setTimeout(() => {
        this.element.classList.remove('hidden');
        this.element.classList.add('transform', 'ease-out', 'duration-300', 'transition', 'translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2');

        // Trigger transition
        setTimeout(() => {
          this.element.classList.add('translate-y-0', 'opacity-100', 'sm:translate-x-0');
        }, 100);

      }, 500);

      // Auto-hide
      setTimeout(() => {
        this.close();
      }, 6500);
    }
  }

  close() {
    // Remove with transition
    this.notificationTarget.classList.remove("opacity-100")
    this.notificationTarget.classList.add("opacity-0")
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview')
  }
}
