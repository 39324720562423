import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-filters"
export default class extends Controller {

  static targets = [ "date", "firstName", "lastName", "validated", "notvalidated" ]

  connect() {
    let param = window.location.search
    if (param.includes("&ordered_by_created_at")) {
      this.dateTarget.classList.add("font-bold")
    }
    if (param.includes("&ordered_by_first_name")) {
      this.firstNameTarget.classList.add("font-bold")
    }
    if (param.includes("&ordered_by_last_name")) {
      this.lastNameTarget.classList.add("font-bold")
    }
    if (param.includes("&validated=true")) {
      this.validatedTarget.classList.add("font-bold")
    }
    if (param.includes("&not_validated=true")) {
      this.notvalidatedTarget.classList.add("font-bold")
    }
  }
}
