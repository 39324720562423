import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = [ "program", "adhesion", "boardmembers", "archives", "contact", "memberzone", "dropdown" ]


  connect() {
    this.checkURL()
    let dropmenu = document.querySelector(".dropmenu")
      document.addEventListener("click", (e) => {
        if (dropmenu.contains(e.target)) {
          return;
        } else {
          this.dropdownTarget.classList.remove('dropdown-visible');
        }
      })
    }

  dropdown(e) {
    this.dropdownTarget.classList.toggle('dropdown-visible')
    e.stopPropagation()
    }


  toggleBoldness(e) {
    if ([...e.currentTarget.classList].includes("font-bold")) {
      return
    } else {
      switch (e.currentTarget) {
        case this.programTarget:
        e.currentTarget.classList.add("font-bold")
        this.adhesionTarget.classList.remove("font-bold")
          break;
        case this.adhesionTarget:
        e.currentTarget.classList.add("font-bold")
        this.programTarget.classList.remove("font-bold")
          break;
      }
    }
  }

  checkURL() {
    let url = window.location.href
    if (url.includes("program")) {
      this.adhesionTarget.classList.remove("font-bold")
      this.programTarget.classList.add("font-bold")
    }
    if (url.includes("adhesion")) {
      this.programTarget.classList.remove("font-bold")
      this.adhesionTarget.classList.add("font-bold")
    }
    if (url.includes("board_members")) {
      this.boardmembersTarget.classList.add("font-bold")
    }
    if (url.includes("archives")) {
      this.archivesTarget.classList.add("font-bold")
    }
    if (url.includes("contact")) {
      this.contactTarget.classList.add("font-bold")
    }
    if (url.includes("user")) {
      this.memberzoneTarget.classList.add("font-bold")
    }
  }

}
