import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="archives"
export default class extends Controller {

  static targets = [ "year" ]

  connect() {
  }

  toggleyear(event) {
    let all_years = document.getElementById("years_container")
    all_years = [...all_years.children]
    const year = event.currentTarget.dataset.year
    // handles hidden
    all_years.forEach(el => {
      if (![...el.classList].includes("hidden")) {
      el.classList.add("hidden")
      }
      else return;

      });
    document.getElementById(`${year}_container`).classList.toggle("hidden")
    // handles boldness
    this.yearTargets.forEach((target) => {
      if ([...target.classList].includes("font-bold")) target.classList.remove("font-bold")
    })
    event.currentTarget.classList.add("font-bold")
  }
}
