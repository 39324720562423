import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-description"
export default class extends Controller {

  static targets = ["description", "formContainer", "form", "section"]

  connect() {
    console.log('connected')
  }

  displayForm() {
    console.log("clicked!")
    this.formContainerTarget.classList.toggle("hidden")
    this.descriptionTarget.classList.toggle("hidden")
  }

  update(e) {
    console.log("updattte!")
    e.preventDefault()
    const url = this.formTarget.action
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.formTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.sectionTarget.outerHTML = data
      })
  }


}
