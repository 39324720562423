import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ["left", "right", "blur"]

  connect() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animated_show")
        }
      })
    },
    {rootMargin: "0px 0px -15% 0px"})


    let leftElements = this.leftTargets
    let rightElements = this.rightTargets
    let blurElements = this.blurTargets

    blurElements.forEach((el) => {
      el.classList.add("blurry_and_hidden")
      observer.observe(el)
    })

    leftElements.forEach((el) => {
      el.classList.add("blurry_and_hidden")
      el.classList.add("left_100")
      observer.observe(el)
    })

  rightElements.forEach((el) => {
      el.classList.add("blurry_and_hidden")
      el.classList.add("right_100")
      observer.observe(el)
    })
  }
}
