import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";


// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    new flatpickr(this.element, {
      enableTime: false,
      minDate: new Date(1970, 1, 1),
      maxDate: new Date(new Date().getFullYear() + 2, 1, 1)
    });
  }

}
