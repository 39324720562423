import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users"
export default class extends Controller {
  static targets = ["card"]

  connect() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animated_show")
        } else {
          entry.target.classList.remove("animated_show")
        }
      })
    })


    let cards = this.cardTargets
    cards.forEach((card)=>{
      observer.observe(card)
    })
  }
}
